
.c-search-input {
  position: relative;
}

.c-search-input input {
  padding-right: 10%;
  &:active {
    font-weight: 600;
    color: black;
  }
}

$placeholder-color: #9D9D9D;
.c-search-input input::placeholder {
  color: $placeholder-color;
}
.c-search-input input::-webkit-input-placeholder {
  color: $placeholder-color;
}
.c-search-input input:-moz-placeholder {
  color: $placeholder-color;
}
.c-search-input input:-ms-input-placeholder {
  color: $placeholder-color;
}

.c-search-input__clear {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  max-width: 40px;
  transition: .15s ease-in-out;
  &:hover, &:focus {
    transform: scale(1.2);
  }
}
