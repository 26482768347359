@import '../../helpers';

.sai-question {
  margin-bottom: 40px;
}

legend.sai-question-text {
  @include font-serif(rem(16), $gray-cool-060, $weight: 500, $lh: 1.8);
  margin-bottom: 16px;
}

.sai-question-text__text {
  padding-left: 8px;
}

.progress {
  clear: both;
}
