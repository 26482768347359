@import "../../helpers.scss";

.chart--base {
  g.graph-lines.vertical {
    stroke: #EEEEEE;
    stroke-width: 1px;
  }

  g.graph-lines.horizontal {
    stroke: #D8D8D8;
    stroke-width: 2px;
  }

  g.graph-lines.vertical line:nth-child(1) {
    stroke: #D8D8D8;
    stroke-width: 2px;
  }
  g.x-axis-labels text {
    font-size: 14px;
    fill: #3D4551;
  }
  .y-axis-labels .value,
  .bar-labels {
    font-size: 14px;
    color: $gray-cool-070;
    fill: currentColor;
  }
  .y-axis-labels .label,
  .y-axis-labels .label-sm {
    font-weight: bold;
    font-size: 16px;
    color: $gray-cool-070;
    fill: currentColor;
    text-align: right;
  }
  .y-axis-labels .label-sm {
    font-size: 14px;
  }
}

table.chart-table--base {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  border-width: 0px;
  td, th {
    padding: 0px;
    vertical-align: top;
  }
  tfoot tr:nth-child(1) td {
    border-top: 2px solid #D8D8D8;
  }
  tfoot tr td:nth-child(1) {
    border-top: none;
  }
}

.chart-table--standard-average-range,
.chart-table--fequency-counts {
  g.range-min text{
    text-anchor: end;
    tspan {
      fill: currentColor;
      dominant-baseline: middle;
    }
  }
  g.range-max text{
    text-anchor: start;
    tspan {
      fill: currentColor;
      dominant-baseline: middle;
    }
  }
  g.background-bars rect {
    fill: $gray-005;
  }
  g.averages rect.primary {
    stroke: #3D4551;
    stroke-width: 1px;
    fill: white;
  }
  g.averages line.comparison {
    stroke: #3D4551;
    stroke-width: 3px;
    stroke-dasharray: 4 1;
  }
  g.averages text {
    font-size: 14px;
  }
  g.footer-legend tspan {
    fill: currentColor;
    font-size: 14px;
    dominant-baseline: middle;
    &.value {
      font-weight: bold;
    }
    &.label {
      font-style: italic;
    }
  }
  tbody tr td:nth-child(1),
  thead tr th:nth-child(1) {
    text-align: right;
    padding-right: 10px;
  }
  thead th {
    font-size: rem(18);
    color: $gray-cool-070;
    font-weight: 600;
  }
  tfoot tr:nth-child(1) td {
    border-top: 2px solid #D8D8D8;
  }
  tfoot tr:last-child td {
    font-size: rem(18);
    color: $gray-cool-070;
    font-weight: 600;
  }
}

.y-axis-labels__label,
.x-axis-labels__label {
  font-size: rem(18);
  color: $gray-cool-070;
  font-weight: 600;
}


.chart-table--standard-average-range--y-label {
  position: absolute;
  transform: rotate(-90deg);
  text-align:center;
  margin-left:-20px;
  margin-top: -50px;
  top:50%;
  font-size: rem(18);
  color: $gray-cool-070;
  font-weight: 600;
}

.report-frequency-count__question-chart .chart-table--standard-average-range--y-label {
  margin-left: -55px;
  margin-top: -46px;
}

.chart--standard-average {

}

.chart--average-range-row-label {
  font-weight: bold;
  line-height: 1.2em;
  padding-top: 10px;
}

.chart--average-range-bar-label,
.chart--average-range-change-label {
  font-size: 14px;
}

.chart--average-range-bar-label span {
  padding-left: 5px;
}

.chart--average-range-change-label {
  font-weight: bold;
  line-height: 16px;
  padding-top: 5px;
}

.report-frequency-count__standard-summary .y-axis-label tspan {
  font-weight: bold;
}

.chart-table--change {
  // padding-right: 15px;
  font-size: 14px;
  font-weight: 600;
  .label {
    font-style: italic;
  }
  .positive {
    color: #169B87;
  }
  .negative {
    color: $red-cool-050;
  }
}

.chart-table--fequency-counts .chart-table--change {
  padding-right: 15px;
}
