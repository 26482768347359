@import '../../helpers';

$checked-color: $blue-050v;
$checked-hover-color: $blue-070v;

.form-inputs,
form.simple_form {
  display: flex;
  flex-direction: column;
  gap: space(4);
}

form.simple_form .hidden {
  display: none;
}

.form-text {
  margin-top: 0;
  margin-bottom: space(2);
  color: $text-muted;
}

label.required:before {
  color: $red-warm-050v;
  content: "* ";
  font-weight: 700;
}

.form-check {
  margin-bottom: 0;
}

.form-switch {
  padding-left: 3em;
  &,
  * {
    cursor: pointer;
  }
}

.form-switch .form-check-input.boolean {
  height: 22px;
  width: 40px;
}

.form-switch .form-check-input {
  margin-left: -3em;
}

.form-switch .form-check-input:checked {
  background-color: $blue-050;
  border-color: $blue-050;
}

.form-check-label.boolean {
  margin-top: 7px;
}

input[readonly] {
  background-color: $gray-005;
}

.input-group__copy-text input {
  font-size: 12px;
}

input[type=checkbox] {
  border-color: $gray-cool-060;
}

.sai-question__response-options {
  gap: 8px;
}

.sai-question {

  // Custom radio buttons - appear in surveys
  .custom-radio--button {
    padding-left: 0;
    margin-bottom: 8px;
  }

  .custom-radio--button .custom-control-input {
    position: absolute;
    left: -9999px;
  }

  .custom-radio--button .custom-control-input:focus {
    outline:none !important;
  }

  .custom-radio--button .custom-control-label {
    color: $checked-color;
    border: 1px solid $checked-color;
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 110px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
  }

  .custom-radio--button .custom-control-input:active + .custom-control-label,
  .custom-radio--button .custom-control-input:focus + .custom-control-label {
    color: #fff;
    background-color: $checked-color;
    box-shadow: 0 0 0 0.2rem rgba($checked-color, 0.25);
  }

  .custom-radio--button .custom-control-input:focus + .custom-control-label::before {
    box-shadow: none;
  }

  .custom-radio--button .custom-control-label:hover {
    color: #fff;
    background-color: $checked-hover-color;
  }

  .custom-radio--button .custom-control-label::before {
    background-color: transparent;
    border: none;
    left: .5rem;
  }
  .custom-radio--button .custom-control-label::after {
    left: .5rem;
  }

  .custom-radio--button .custom-control-input:checked ~ .custom-control-label {
    color: #fff;
    background-color: $checked-color;
  }

  .custom-radio--button .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent;
  }

  .custom-radio--button .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
  }

  .custom-radio--button .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: transparent;
    border: none;
  }

  // Custom radio toggles (circular) — appear in surveys
// https://moderncss.dev/pure-css-custom-styled-radio-buttons/


  .form-check.form-switch {
    padding-left: 1em;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 5px;
  }

  input[type="radio"].custom-control-input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;

    width: 25px;
    height: 25px;
    border: 1.5px solid #acb5be;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  input[type="radio"].custom-control-input::before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 2px 2px $checked-color;
    background-color: $checked-color;
  }

  input[type="radio"].custom-control-input:checked {
    border-color: $checked-color;
  }

  input[type="radio"].custom-control-input:checked::before {
    transform: scale(1);
  }

  input[type="radio"].custom-control-input:focus {
    outline: 3px solid rgba($checked-color,.25);
  }
}
