@import '../../helpers';

.tout {
  padding: rem(12) rem(18);
  background-color: $green-010;
}
.tout__heading {
  @include font-serif($size: 22px, $weight: 500, $lh: 1.62);
  margin-bottom: 0;
}
.help-cta {
  line-height: 1.1;
  color: $green-050v;
}
.help-cta a {
  color: $green-050v;
  text-decoration: none;
}