@import "../../helpers.scss";

.popover {
  border-radius: 0px;
  border-color: $blue-030;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: $blue-030;
}
