@import "../../helpers.scss";

$breakpoints: xs sm md lg xl xxl;

.c-breakpoints {
  display: none;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.c-breakpoints__breakpoint {
  height: 25px;
  width: 35px;
  border: 1px solid $gray-500;
  margin-right: 3px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: rem(12);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: .25s ease-in-out;
  padding-left: 3px;
  padding-right: 3px;
  @each $b in $breakpoints {
    @include media-breakpoint-only($b) {
      &.#{$b} {
        background: $primary;
        color: white;
        font-weight: bold;
      }
    }
  }
}
