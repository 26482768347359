@import "../../helpers.scss";

tr.light {
  &,
  th {
    background: $gray-005;
    border-top: $gray-cool-010 1px solid;
    color: $gray-cool-060;
  }
}

// max width before we must scroll comparison tables
$comparison_table_scroll_width: 1366px;

.school-list__table a {
  text-decoration: none;
}
.school-list__table a:hover,
.school-list__table a:active,
.school-list__table a:focus {
  text-decoration: underline;
}
.table__row--no-border-top-first-row tr:first-of-type td {
  border-top: 0;
}
.row-expander {
  font-weight: 400;
  font-size: rem(12);
}
a.row-expander--link {
  color: $gray-cool-070;
}
a.row-expander--link:hover,
a.row-expander--link:focus,
a.row-expander--link:active {
  text-decoration: none;
}
.table .thead-dark th {
  color: #fff;
  background-color: $gray-cool-090;
}
.table.table--invitations-help th {
  line-height: 1.625;
}
.table.table--invitations-help ol,
.table.table--invitations-help ul {
  padding-left: 20px;
}

.report-standard-indicator-scores__table {
  td,
  th {
    vertical-align: middle;
  }
  thead tr:first-child th {
    border-top: none;
  }
  thead th:first-child {
    font-size: rem(22);
  }
  tbody td:first-child {
    font-size: rem(24);
    vertical-align: top;
  }
  tbody tr:first-child td:first-child {
    font-size: rem(18);
    vertical-align: middle;
  }
  tbody td:last-child {
    font-size: rem(24);
    border-left: 1px solid $gray-cool-010;
    border-right: 1px solid $gray-cool-010;
  }
  tbody tr:last-child td {
    border-bottom: 1px solid $gray-cool-010;
  }
  td.high {
    color: #fff;
    background-color: $blue-050v;
  }
  td.average {
    background-color: $gray-cool-010;
  }
  td.low {
    color: #fff;
    background-color: $red-cool-050;
  }
}

.report-school-comparison__scroll-hint {
  color: $gray-cool-070;
  font-style: italic;
  text-align: right;
}
@media only screen and (min-width: $comparison_table_scroll_width) {
  .report-school-comparison__scroll-hint {
    display: none;
  }
}

.report-school-comparison__scroll {
  position: relative;
  min-width: 100%;
  z-index: 1;
  margin: auto auto 4rem;
  overflow-x: auto;
}

.report-school-comparison__table {
  table-layout: fixed;
}

.report-school-comparison__table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 0;
  border-bottom: 1px solid $gray-cool-010;
  col {
    @media print {
      width: auto;
    }
  }
  td.school-names {
    font-size: 15px;
  }
  th,
  td {
    border-style: solid;
    border-width: 1px 1px 0 0;
    padding: 8px 6px 8px 8px;
    border-color: white;
    &.teacher-counts,
    &.standard-totals {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right: 1px solid $gray-cool-010;
    }
    &.school-names {
      border-width: 1px 0 0 1px;
    }
    background-clip: padding-box;

    &.standard-totals,
    &.school-names,
    &.teacher-counts,
    &.standards {
      position: -webkit-sticky;
      position: sticky;
      background: white;
      background-clip: padding-box;
      z-index: 2;
      &.borders {
        border-color: $gray-cool-010;
      }
      @media only screen and (max-width: $comparison_table_scroll_width) {
        &.left-edge-shadow {
          &:after {
            content: "";
            display: block;
            position: absolute;
            right: -10px;
            height: calc(100% + 1px);
            width: 10px;
            z-index: 10000;
            top: 0;
            bottom: 0;
            pointer-events: none;
            background: linear-gradient(90deg, rgba(124, 124, 124, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
          }
        }
      }
    }
    &.standard-totals {
      left: 0;
      padding-right: 3rem;
    }
    &.school-names {
      left: 0;
    }
    &.teacher-counts {
      font-weight: bold;
    }
    &.standards {
      border: none;
    }
    &.rigorous-content-for-each-learner {
      background-color: $frame-orange;
    }
    &.transformational-processes {
      background-color: $frame-green;
    }
    &.conditions-for-success,
    &.sai-2011 {
      background-color: $frame-blue;
      color: white;
    }
    @media only screen and (max-width: $comparison_table_scroll_width) {
      &.right-edge-shadow {
        position: sticky;
        right: 0;
        border-width: 0;
        max-width: 0;
        padding: 0;
        &::before {
          background: linear-gradient(-90deg, rgba(124, 124, 124, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
          content: "";
          display: block;
          width: 10px;
          position: absolute;
          z-index: 10000;
          left: -10px;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
  tr .right-edge-shadow {
    padding: 0;
    border-left-width: 0;
  }
  thead th {
    border-bottom: none;
  }
  caption {
    display: none;
    @media print {
      display: table-caption;
    }
  }
  tr.odd {
    .rigorous-content-for-each-learner-datacell {
      background-color: transparentize($frame-orange, 0.95);
    }
    .transformational-processes-datacell {
      background-color: transparentize($frame-green, 0.95);
    }
    .conditions-for-success-datacell,
    .sai-2011-datacell {
      background-color: transparentize($frame-blue, 0.95);
    }
  }
  tr.even {
    .rigorous-content-for-each-learner-datacell {
      background-color: transparentize($frame-orange, 0.98);
    }
    .transformational-processes-datacell {
      background-color: transparentize($frame-green, 0.98);
    }
    .conditions-for-success-datacell,
    .sai-2011-datacell {
      background-color: transparentize($frame-blue, 0.98);
    }
  }
}

.report-school-comparison__thead-key-row {
  th,
  td {
    font-size: rem(13px);
    line-height: 1.1;
  }
}

tr.report-school-comparison__thead-standard-row {
  th {
    font-size: rem(13px);
    font-weight: normal;
    vertical-align: bottom;
    &:not(.borders) {
      @media print {
        font-size: rem(11px);
      }
    }
  }
  th:first-child {
    font-weight: bold;
    text-align: left;
  }
  th.transformational-processes {
    background-color: rgba($frame-green, 0.6);
    color: #000;
  }
  th.rigorous-content-for-each-learner {
    background-color: rgba($frame-orange, 0.6);
    color: #000;
  }
  th.conditions-for-success {
    background-color: rgba($frame-blue, 0.6);
    color: #000;
  }
}

.report-school-comparison__thead-standard-row__values {
  font-size: rem(12px);
}

.report-school-comparison__table-key {
  font-size: rem(14px);
  line-height: rem(16px);
  font-style: italic;
  color: black;
  display: flex;
  align-items: center;
  .report-school-comparison__key-box {
    border: 1px solid $blue-010;
  }
}

.report-school-comparison__key-box {
  border-radius: 9px;
  min-width: 60px;
  min-height: 30px;
  @media print {
    min-width: 50px;
    img {
      padding: 0.1rem 0 0.2rem;
    }
  }
}

.report-school-comparison__key-box.avg {
  border: 1px dashed $red-warm-050v;
}

.report-school-comparison__key-box.skillful {
  border: 1px solid $green-030;
  background-color: rgba($green-010, 1);
}
