@import "../../helpers.scss";

.sai-logo {
  @include media-breakpoint-down(md) {
    width: 80%;
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
