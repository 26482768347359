@import "../../helpers.scss";

.report-link {
  text-decoration: none;
  color: $gray-cool-070;
  &:hover,
  &:active,
  &:focus {
    color: $blue-050v;
  }
}

.report__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: hidden;
  h2 {
    font-size: 1.5rem;
    margin: 0 0.75rem 0 0;
    @include media-breakpoint-up(lg) {
      flex-basis: 1rem;
    }
    @media print {
      min-width: 100%;
      margin-bottom: 1rem;
    }
  }
  .report__metadata {
    display: flex;
    row-gap: 1.5rem;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    @media screen {
      flex-grow: 1;
    }
    @media print {
      margin-bottom: 2rem;
    }
  }
}

.report__header-name {
  border-left: 2px solid $gray-cool-010;
  color: $gray-cool-010;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 0.75rem;
  @media screen {
    @include media-breakpoint-down(md) {
      min-width: 100%;
    }
  }
  .indicator {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    .indicator__label {
      border-bottom: 1px solid $gray-cool-010;
      color: $gray-cool-050;
      font-weight: lighter;
      margin: 0;
      padding-bottom: 0.25rem;
    }
    .indicator__value {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: $gray-cool-060;
      font-weight: normal;
      margin: 0;
      padding-top: 0.25rem;
      white-space: nowrap;
      .indicator__icon {
        font-size: 1.5rem;
        margin: -5px 0;
        height: 1.5rem;
        width: 1.5rem;
      }
      .indicator__text {
        padding: 0 0.25rem;
      }
    }
  }
  button {
    border-radius: 0;
    &:focus {
      outline: 2px solid $blue-050v;
    }
  }
  h1 a {
    text-decoration: none;
  }
  &.report__header-name--no-border {
    border-left: none;
  }
}


.report__nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 3rem;
  margin-top: 2rem;
}

.report-header-underline {
  border-bottom:2px solid rgba(0, 0, 0, 0.075);
}

.report-header-underline__thick {
  border-bottom:4px solid rgba(0, 0, 0, 0.075);
}

.report-standard-indicator-scores__keys {
  .key {
    width: 40px;
    height: 32px;
    margin-right: 10px;
  }
  .key-values {
    line-height: 1;
  }
  .key.high {
    background-color: $blue-050v;
  }
  .key.average {
    background-color: $gray-cool-010;
  }
  .key.low {
    background-color: $red-cool-050;
  }
}

.report-standard-indicator-scores__section_name {
  font-size: 1.375rem;
  border-left: 16px solid;
  margin-bottom: space(2);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  &.transformational-processes {
    border-color: $frame-green;
    background-color: rgba($frame-green, .6);
    color: #000;
  }
  &.rigorous-content-for-each-learner {
    border-color: $frame-orange;
    background-color: rgba($frame-orange, .6);
    color: #000;
  }
  &.conditions-for-success {
    border-color: $frame-blue;
    background-color: rgba($frame-blue, .6);
    color: #000;
  }
}

.report-frequency-count__summary-keys {
  .key {
    width: 20px;
    height: 20px;
  }
}

.report-frequency-count__standard-summary,
.report-frequency-count__standard {
  margin-bottom: 60px;
}

.report-frequency-count__standard-summary a {
  text-decoration: none;
  color: $gray-cool-070;
  position: relative;
  display: block;
  .chart-tooltip {
    transition: opacity .5s;
    opacity: 0;
    position: absolute;
    color: black;
    padding-left: 5px;
    span {
      border-radius: 2px;
      position: relative;
      padding: 0px 4px;
      background-color: $gray-005;
      font-size: 0.8em;
    }
    span:before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left:-10px;
      border: solid 5px transparent;
      border-right-color: $gray-005;
      z-index: 1;
    }
  }
  &:hover,
  &:active,
  &:focus {
    .chart-tooltip {
      opacity: 1;
    }
  }
  &:hover .y-axis-label text {
    text-decoration: underline;
  }
}

.report-frequency-count__question {
  color: $gray-cool-070;
}

.report-frequency-count__question-body {
  font-size: rem(18px);
}

.report-frequency-count__question-standard {
  font-size: rem(16px);
  font-weight: 300;
}

.report-frequency-count__question-stats {
  background-color: rgba(0, 0, 0, 0.075);
}

.report-frequency-count__question-chart {
  padding: 30px;
}

.report-school-comparison {
  page: report-school-comparison;
  .section-description {
    max-width: 1024px;
    padding-right: 10%;
    color: $gray-cool-070;
  }
}
