@import '../../helpers.scss';

.c-checklist {
  display: flex;
  flex-direction: column;
  gap: space(3);
}

.c-checklist__list {
  max-height: 50vh;
  padding: 0 space(1);
  overflow: auto;
}

.c-checklist__list .form-check,
.c-checklist__list .form-check label {
  cursor: pointer;
  font-weight: normal;
  &:hover {
    color: $primary;
  }
}

.c-checklist__list .form-check input:checked + label {
  font-weight: bold;
}
