@import '../../helpers';

html, body {
  height: 100%;
}

label {
  font-weight: 600;
  line-height: 1.2;
  color: $gray-cool-090;
}
h4 {
  margin-bottom: calc($spacer/2);
}
th {
  line-height: 1.1;
}
legend {
  font-weight: 600;
}
