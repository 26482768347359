@import '../../helpers';

.nav-utilities {
  justify-content: flex-end;
  @include media-breakpoint-down(md) {
    justify-content: space-between
  }
}

.nav-main a,
.nav-utilities a{
  text-decoration: none;
  @include font-sans-serif($size: 14px, $weight: 700, $lh: 1, $color: $body-color);
}
.nav__in-page-tabs a{
  text-decoration: none;
  @include font-sans-serif($size: 16px, $weight: 700, $lh: 1, $color: $body-color);
}
.nav-main a:hover,
.nav-main a:focus,
.nav-main a:active,
.nav-utilities a:hover,
.nav-utilities a:focus,
.nav-utilities a:active,
.nav__in-page-tabs a:hover,
.nav__in-page-tabs a:focus,
.nav__in-page-tabs a:active,
.nav__in-page-tabs a.active {
  color: $link-color;
}
.nav-main a,
.nav__in-page-tabs a {
  border-bottom: 2px solid transparent;
}
.nav-main a:hover,
.nav-main a:focus,
.nav-main a:active,
.nav-main a.active,
.nav__in-page-tabs a:hover,
.nav__in-page-tabs a:focus,
.nav__in-page-tabs a:active,
.nav__in-page-tabs a.active {
  border-bottom: 2px solid $link-color;
}
.nav-item--has-left-separator a {
  padding-left: 0;
}
.nav-item--has-left-separator a::before {
  content: '|';
  padding-right: 1rem;
}

.report__nav .nav-item .active:not(.dropdown-item) {
  text-decoration: none;
  color: black;
  border-bottom: 3px solid $blue-050;
  font-weight: 700;
}
