@import '../../helpers';

.line-height-minimal {
  line-height: 1.1;
}
.no-text-decoration {
  text-decoration: none;
}
.link-gray {
  color: $gray-cool-070;
}
.label-gray {
  color: $gray-cool-050;
}
.flex-grow-0 {
  flex-grow: 0;
}
