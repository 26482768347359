// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Custom Bootstrap Variables
// ---------------------------------------------------------------------------
// Available variables:
// https://github.com/twbs/bootstrap/blob/v5.3.2/scss/_variables.scss
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./colors";
@import "./variables";

$grid-gutter-width:           20px;

$font-family-sans-serif:      'Public Sans', sans-serif;
$font-family-monospace:       'Public Sans', serif;
$font-family-base:            $font-family-sans-serif;

$line-height-base:            1.75;

$link-color:                $blue-050v;
$link-decoration:           underline;
$link-hover-color:          $blue-060v;
$link-hover-decoration:     none;

// Spacing
$enable-negative-margin: true;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * 1),
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3)
);

// Using typography sizes from USWDS 2.0 for Public Sans
$h1-font-size:                $font-size-base * 1.375;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base * 1;
$h5-font-size:                15px;
$h6-font-size:                13px;
$headings-font-weight:        600;
$headings-margin-bottom:      calc($spacer/1.5);
$headings-color:              $gray-cool-090;
$small-font-size:             14px;

$body-color: $gray-cool-050;
$text-muted: $gray-cool-040;

$input-btn-padding-x:         1.25rem;
$btn-padding-x:               $input-btn-padding-x;

$border-color:                #ced4da;

$table-bg:                    $white;
$table-cell-padding:          8px;
$table-border-color:          $gray-cool-010;
$table-hover-bg:              $gray-005;
$table-head-bg:               $gray-005;
$table-head-color:            $gray-cool-060;
$table-dark-bg:               $gray-cool-030;
$table-dark-hover-bg:         $gray-cool-040;
$table-dark-border-color:     $gray-cool-070;
$table-dark-color:            $gray-cool-090;

$progress-bar-color:          $blue-050v;
$progress-bar-bg:             $blue-050v;
$progress-border-radius:      0;
$progress-height:             8px;

$modal-header-border-width:   0;

$card-title-color:            $gray-cool-090;
$card-spacer-x:               1rem;
$card-border-radius:          0;
$card-border-color:           transparent;
$card-title-spacer-y:         1rem;


$dropdown-link-active-bg:     $blue-050v;
$dropdown-link-color:         $gray-cool-090;
$dropdown-link-hover-color:   $blue-050v;
$dropdown-link-hover-bg:      $gray-005;
