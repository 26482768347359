@use "sass:math";

@mixin font-sans-serif($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $font-family-sans-serif;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

@mixin font-serif($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $font-family-serif;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  letter-spacing: .03em;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

// @param {Number} $pixel-size - Pixel Value to convert
// @return {Number} - Rem value
@function rem($pixel-size) {
  $rems: math.div( strip-unit($pixel-size), strip-unit($font-size-base-pixels) );
  @return #{$rems}rem;
}

// @param {Number} $pixel-size - Pixel Value to convert
// @return {Number} - Em value
@function em($pixel-size) {
  $ems: math.div( strip-unit($pixel-size), strip-unit($font-size-base-pixels) );
  @return #{$ems}em;
}

.color__black {
  color: #000;
}

$color-gray: $gray-005 $gray-cool-010 $gray-cool-030 $gray-cool-040 $gray-cool-050 $gray-cool-060 $gray-cool-070 $gray-cool-090;
$color-gray-names: gray-005 gray-cool-010 gray-cool-030 gray-cool-040 gray-cool-050 gray-cool-060 gray-cool-070 gray-cool-090;

@for $i from 1 through length($color-gray) {
  $color: nth($color-gray, $i);
  $name: nth($color-gray-names, $i);
  .color__#{$name} {
    color: #{$color};
  }
}

$color-red: $red-cool-010v $red-cool-030 $red-cool-050 $red-cool-060 $red-cool-080v;
$color-red-names: red-cool-010v red-cool-030 red-cool-050 red-cool-060 red-cool-080v;

@for $i from 1 through length($color-red) {
  $color: nth($color-red, $i);
  $name: nth($color-red-names, $i);
  .color__#{$name} {
    color: #{$color};
  }
}

$color-green: $green-cool-005 $green-cool-020v $green-cool-030v $green-cool-050 $green-010 $green-030 $green-050 $green-050v $green-060 $green-070;
$color-green-names: green-cool-005 green-cool-020v green-cool-030v green-cool-050 green-010 green-030 green-050 green-050v green-060 green-070;
@for $i from 1 through length($color-green) {
  $color: nth($color-green, $i);
  $name: nth($color-green-names, $i);
  .color__#{$name} {
    color: #{$color};
  }
}
