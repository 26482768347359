@import '../../helpers';

.main-header {
  position: relative;
}

.main-header__nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.main-header__nav .nav-main {
  justify-content: flex-end;
  @include media-breakpoint-down(md) {
    justify-content: space-between;
  }
}

.page-heading {
  margin-bottom: space(4);
  display: flex;
  align-items: baseline;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    gap: space(1);
  }
}


.page-heading h1 {
  margin-bottom: space(2)
}

.page-heading__engagement {
  @include font-sans-serif($size: 20px, $weight: 700, $lh: 1.2);
}

.page-heading__term {
  @include font-sans-serif($size: 16px, $weight: 600);
  line-height: inherit;
  display: flex;
  align-items: flex-end;
}

.report-page__header,
.admin-page__header {
  background: $page-header-bg;
  padding-top: space(4);
  margin-bottom: space(4);
  @include media-breakpoint-down(md) {
    .text-end .btn {
      width: 100%;
      margin-bottom: space(4);
    }
  }
}

.report-page__header {
  margin-bottom: space(2);
}
