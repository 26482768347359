//based on https://designsystem.digital.gov/design-tokens/color/system-tokens/
// Magic Number: Difference in grade b/w any two colors
// Magic Number: 40+ — WCAG 2.0 AA Large Text (~18px+)Magic Number: 50+ — WCAG 2.0 AA
// 0 = pure white; 100 = pure black

$gray-005            : #f0f0f0;
$gray-cool-010       : #dcdee0;
$gray-cool-030       : #a9aeb1;
$gray-cool-040       : #8d9297;
$gray-cool-050       : #71767a;
$gray-cool-060       : #565c65;
$gray-cool-070       : #3d4551;
$gray-cool-090       : #3d4551;

// primary
$blue-010       : #d9e8f6;
$blue-030       : #73b3e7;
$blue-050       : #2378c3;
$blue-050v      : #0d7ea2;
$blue-060v      : #07648d;
$blue-070v      : #074b69;

// secondary
$green-010       : #dfeacd;
$green-030       : #9bb672;
$green-050       : #607f35;
$green-050v      : #538200;
$green-060       : #4c6424;
$green-070       : #3c4a29;

// accent 1
$orange-010v     : #fce2c5;
$orange-030v     : #fa9441;
$orange-050v     : #c05600;
$orange-warm-060 : #914734;
$orange-warm-070 : #633A32;

// accent 2
$red-cool-010v   : #F8DFE2;
$red-cool-030    : #E09AA6;
$red-cool-050    : #CD425B;
$red-cool-060    : #9E394B;
$red-cool-080v   : #5C1111;

//info colors
$cyan-005        : #E7F6F8;
$cyan-020        : #9DDFEB;
$cyan-030v       : #00BDE3;
$cyan-040v       : #009BC2;
$blue-cool-060   : #2E6276;

//success colors
$green-cool-005  : #ECF3EC;
$green-cool-020v : #70E17B;
$green-cool-030v : #21C834;
$green-cool-050  : #4D8055;
$blue-cool-060   : #446443;

//warning colors
$yellow-005      : #FAF3D1;
$yellow-010v     : #FEE685;
$gold-020v       : #FFBE2E;
$gold-30v        : #E5A000;
$gold-50v        : #936F38;

//error colors
$red-warm-010    : #F4E3DB;
$red-warm-030v   : #F39268;
$red-warm-050v   : #D63E04;
$red-60v         : #B50909;
$red-70          : #6F3331;

// frame colors
$frame-blue      : #0771B3;
$frame-green     : #39B54A;
$frame-orange    : #F7941D;

$primary:       $blue-050v;
$secondary:     $gray-cool-030;
$success:       $green-cool-030v;
$info:          $cyan-030v;
$warning:       $gold-020v;
$danger:        $red-warm-050v;
$light:         $gray-005;
$dark:          $gray-cool-070;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);
