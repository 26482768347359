@import '../../helpers';

a.btn,
.btn-link,
a.dropdown-item {
  text-decoration: none;
  line-height: 1.625;
}
.btn-sm {
  min-width: 60px;
}
// btn
.assessment-nav__direction .fas {
  font-size: 30px;
}
.btn.assessment-nav__save-done {
  padding-top: 4px;
  padding-bottom: 4px;
}
