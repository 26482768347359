@import "../../helpers.scss";

@media print {
  @page {
    prince-pdf-page-colorspace: rgb;
    prince-shrink-to-fit: auto;
    @bottom {
      font-family: $font-family-sans-serif;
      content: counter(page);
    }
    size: letter portrait;
    margin: 0.5in;
  }

  @page report-school-comparison {
    size: letter landscape;
  }

  .page-break-inside__avoid {
    page-break-inside: avoid;
  }

  .page-break-after__always {
    page-break-after: always;
  }

  .page-break-before__always {
    page-break-before: always;
  }

  .chart-table--base caption {
    display: table-caption;
    caption-side: top;
    prince-caption-page: following;
    padding-bottom: 30px;
  }

  .report-school-comparison__table caption {
    caption-side: top;
    prince-caption-page: following;
  }

  table tr {
    page-break-inside: avoid;
  }

  .ms-4.chart--base {
    margin-left: 0 !important;
  }


  // Header Icon ------------------------------------
  .indicator__icon svg {
    height: 6mm !important;
    width: 6mm !important;
  }

  // Headings ------------------------------------
  #print-report {
    h1 {
      font-size: 6mm !important;
      svg {
        height: 6mm !important;
        width: 6mm !important;
      }
    }
  }

  // TOC ----------------------------------------

  #toc a.toc-link:after {
    content: leader('.') target-counter(attr(href), page);
    color: $gray-cool-050;
  }

  #toc a.toc-link {
    text-decoration: none;
    display: block;
    width: 100%;
    margin-bottom: 1mm;
  }
  #toc a.toc-link--nested {
    padding-left: 6.5mm !important;
    margin-bottom: 0;
  }

  #toc a.toc-link h1 {
    margin-bottom: 0;
    font-size: 4mm !important;
  }

  #toc a.toc-link h3 {
    font-size: 3.25mm !important;
  }

  #toc a.toc-link h1,
  #toc a.toc-link h3,
  #toc a.toc-link svg {
    display: inline-block;
    vertical-align: middle;
  }
  #toc a.toc-link svg {
    fill: black !important;
    color: black !important;
  }
  #toc svg.svg-inline--fa {
    height: 4mm !important;
    width: 4mm !important;
    margin-right: 1mm;
  }

  // ----------------------------------------

  .report-frequency-count__question-body {
    line-height: 1.3;
  }
  .report-frequency-count__question-number {
    margin-right: 2mm;
  }
  .report-standard-indicator-scores__section_name {
    margin-bottom: 4mm;
  }

  .report-frequency-count__question-body {
    line-height: 1.3;
  }

  .report-frequency-count__question-number {
    margin-right: .125in;
  }

  .report-standard-indicator-scores__section_name {
    margin-bottom: .25in;
  }

  .indicator__value {
    display: block !important;
  }

  .indicator__text {
    font-family: "Public Sans", sans-serif !important;
  }

  .indicator__value span {
    display: inline-block !important;
  }
}
