@import '../../helpers';

table.school-list__table {
  thead tr:first-child th {
    border-top: 0px;
  }
  thead tr:last-child th {
    background-color: $gray-005;
  }
  th:last-child, td {
    vertical-align: middle;
  }
}

.project-list__table {
  thead th {
    border: 0px;
  }
  th.actions {
    padding: 0px;
  }
}
