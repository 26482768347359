@import '../../helpers';

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-header {
  padding-bottom: 0;
}

.modal-body {
  padding-top: 0;
}

.modal-body > div,
.modal-inner-content {
  display: flex;
  flex-direction: column;
  gap: space(2);
}

.modal-return-finish-later .modal-header,
.modal-confirm-archive .modal-header {
  padding-bottom: 0;
}
.modal-return-finish-later .modal-body,
.modal-confirm-archive .modal-body,
.modal-copy-return-link .modal-body {
  padding-top: .25rem;
}
.modal__h2 {
  font-size: rem(22);
}
.modal li {
  padding-bottom: .25em;
}
.modal li.list-item--no-padding-bottom {
  padding-bottom: 0;
}
.modal-body p {
  // sometimes these are getting useragent stylesheet styling for th
  font-weight: 500;
}
#share_district_report_modal {
  .modal-header {
    padding-bottom: 8px;
  }
  .modal-body {
    padding-top: 0px;
  }
}
.report_modal__content {
  border-top: 1px solid #F0F0F0;
  padding-top: 16px;
  small {
    font-size: 12px;
  }
}

// Handle multiple modal backdrops
// .modal-backdrop:nth-child(2n-1) {
//   opacity : 0;
// }
