@import '../../helpers';


.hover-card--default {
  @include card-with-shadow;
  position: relative;
  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
  }
}
.card__overview tr:last-of-type td {
  border-bottom: 1px solid $gray-cool-010;
}